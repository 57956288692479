var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n)?_c('div',{staticClass:"my-commune-address pb-xxl"},[_c('header',[(_vm.breadcrumb)?_c('breadcrumb',{attrs:{"content":_vm.breadcrumb}}):_vm._e(),_c('div',{staticClass:"page-header wrapper row"},[_c('div',{staticClass:"page-header__title col-xxxs-20 col-l-20"},[_c('h1',{staticClass:"main-title mb-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.communeAddresses.title)}})])])],1),_c('div',{staticClass:"wrapper"},[(
        !_vm.isSended &&
        _vm.i18n.communeAddresses.optionHtml.displayContent.length > 0
      )?_c('div',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.communeAddresses.optionHtml.htmltext)}}):_vm._e(),_c('ValidationObserver',{ref:"observer",staticClass:"col-xxs-20",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [(!_vm.isSended)?_c('div',[_c('form',[_c('Gravity',{attrs:{"customStyle":{
              'input--commune-alt': true,
              macommune: true,
            },"idForm":_vm.idFormulaire}}),_c('CustomLink',{staticClass:"submitAdress",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
              tag: 'button',
              label: _vm.i18n.communeAddresses.labelButton,
            },"disabled":invalid,"modifiers":['btn', 'green', 'big', 'rectrounded'],"type":"button"},nativeOn:{"click":function($event){return _vm.onSubmit($event)}}})],1),(_vm.globalI18n)?_c('span',{staticClass:"small col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.globalI18n.requiredFields)+" ")]):_vm._e(),(_vm.feedbackMessage)?_c('feedback-message',{staticClass:"mt-s",attrs:{"content":{
            type: _vm.hasError ? 'error' : 'success',
            htmltext: _vm.feedbackMessage,
          }}}):_vm._e()],1):_c('div',[_c('div',{staticClass:"confirm-text mb-s"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"ui-check-filled"}}),_vm._v(" "+_vm._s(_vm.i18n.address.sendFeedback)+" "),_c('br'),_c('span',{staticClass:"text-gray"},[_vm._v(_vm._s(_vm.i18n.address.sendFeedback2))])]),_c('div',{staticClass:"confirm-follow mt-s"},[_c('CustomLink',{staticClass:"submitAdress",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
              tag: 'button',
              label: _vm.i18n.address.backHome,
            },"modifiers":['btn', 'green', 'big', 'rectrounded'],"type":"button"},nativeOn:{"click":function($event){return _vm.nav()}}})],1)])]}}],null,false,326433091)})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }