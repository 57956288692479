var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ean-commune-gravity mb-s"},[_c('ValidationProvider',{attrs:{"custom-messages":{
      required: _vm.i18n.gravity.required,
      regex: _vm.i18n.gravity.errorEan,
    },"rules":{
      required: _vm.form.isRequired,
      regex: /^([5414]\d{17})$/,
    },"name":"ean-commune"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":errors,"html":_vm.form.description,"label":_vm.form.label,"maxlength":18,"minlength":18,"placeholder":_vm.form.placeholder && _vm.form.placeholder.length > 0
          ? _vm.form.placeholder
          : null,"required":_vm.form.isRequired},on:{"input":validate},model:{value:(_vm.eanNew),callback:function ($$v) {_vm.eanNew=$$v},expression:"eanNew"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }