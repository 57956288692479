















































































import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import { useMutations } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'radio-gravity',
  components: { CheckBlock, VCheckbox },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const input = ref('')
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])

    onBeforeMount(() => {
      for (let i = 0; i < props.content.choices.length; i++) {
        if (props.content.choices[i].isSelected) {
          input.value = props.content.choices[i].value
        }
      }
    })
    watch(input, newval => {
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: input.value,
      })
    })

    return {
      input,
      form: props.content,
    }
  },
})
