



























































































import { defineComponent, ref, watch } from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'
import { useMutations, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'compteur-gravity',
  components: { VInput },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const input = ref('')
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])

    watch(input, newval => {
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: input.value,
      })
    })

    return {
      input,
      form: props.content,
      ...useState('faq', ['i18n']),
    }
  },
})
