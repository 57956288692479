



















import { defineComponent, onMounted, ref } from '@vue/composition-api'

import Axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import Text from '@/components/faq/gravity/Text.vue'
import TextArea from '@/components/faq/gravity/TextArea.vue'
import Section from '@/components/faq/gravity/Section.vue'
import Title from '@/components/faq/gravity/Title.vue'
import CaptchaForm from '@/components/faq/gravity/CaptchaForm.vue'
import Name from '@/components/faq/gravity/Name.vue'
import Checkbox from '@/components/faq/gravity/CheckBox.vue'
import Fileupload from '@/components/faq/gravity/Fileupload.vue'
import Address from '@/components/faq/gravity/Address.vue'
import Phone from '@/components/faq/gravity/Phone.vue'
import Email from '@/components/faq/gravity/Email.vue'
import Ean from '@/components/faq/gravity/Ean.vue'
import Compteur from '@/components/faq/gravity/Compteur.vue'
import EanCommune from '@/components/faq/gravity/EanCommune.vue'
import Label from '@/components/faq/gravity/Label.vue'
import Radio from '@/components/faq/gravity/Radio.vue'
import { useMutations } from '@u3u/vue-hooks'
import { isCommuneResa } from '@/inc/utils'

export default defineComponent({
  name: 'gravity',
  props: {
    idForm: {
      type: String,
      required: true,
    },
    customStyle: {
      type: Object,
      required: false,
    },
  },
  setup(props, ctx) {
    const data = ref('')
    const typeSend = [
      'name',
      'address',
      'text',
      'textarea',
      'captcha',
      'number',
      'fileupload',
      'email',
      'checkbox',
      'address',
      'phone',
      'EAN',
      'radio',
      'compteur',
      'GdprRecaptcha',
    ]
    const formTypes = {
      text: Text,
      textarea: TextArea,
      section: Section,
      name: Name,
      fileupload: Fileupload,
      number: Text,
      title: Title,
      checkbox: Checkbox,
      email: Email,
      address: Address,
      captcha: CaptchaForm,
      phone: Phone,
      EAN: isCommuneResa ? EanCommune : Ean,
      label: Label,
      GdprRecaptcha: CaptchaForm,
      radio: Radio,
      compteur: Compteur,
    }
    const { SET_FORMS, RESET_FORMS } = useMutations('faq', [
      'SET_FORMS',
      'RESET_FORMS',
    ])
    const isLoading = ref(false)

    onMounted(() => {
      isLoading.value = true
      Axios.get(`${getApiUrl()}/forms/${props.idForm}`).then(resp => {
        RESET_FORMS()
        // eslint-disable-next-line prefer-destructuring
        data.value = resp.data[0]
        for (let i = 0; i < resp.data[0].fields.length; i++) {
          if (typeSend.includes(resp.data[0].fields[i].type)) {
            SET_FORMS({
              type: resp.data[0].fields[i].type,
              id: resp.data[0].fields[i].id,
              value: '',
              isRequired: resp.data[0].fields[i].isRequired,
            })
            isLoading.value = false
          }
        }
      })
    })

    return {
      data,
      typeSend,
      formTypes,
      isLoading,
    }
  },
})
