var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',{staticClass:"radio-gravity mt-xs mb-s row"},_vm._l((_vm.form.choices),function(choice,index){return _c('div',{key:index,staticClass:"checkblock-container mb-xxs col-xxs-20"},[_c('ValidationProvider',{attrs:{"custom-messages":{
        required: 'Champ requis',
      },"rules":{
        required: _vm.form.isRequired,
      },"name":"radio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],attrs:{"erros":errors,"id":choice.value,"name":choice.text,"type":"radio"},domProps:{"value":choice.value,"checked":_vm._q(_vm.input,choice.value)},on:{"input":validate,"change":function($event){_vm.input=choice.value}}}),_c('label',{attrs:{"for":choice.value}},[_vm._v(_vm._s(choice.text))])]}}],null,true)}),_c('br')],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }