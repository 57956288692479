








































































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'

import Message from '@/components/ui/Message.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import Header from '@/components/Header.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Gravity from '@/components/form/Gravity.vue'
import { getApiUrl } from '@/inc/app.config'
import Axios from 'axios'

export default defineComponent({
  name: 'my-commune-address',
  components: {
    Message,
    Breadcrumb,
    Header,
    CustomLink,
    FeedbackMessage,
    Gravity,
  },
  setup(_props, ctx) {
    const { $logger } = ctx.root
    const message = ref(true)
    const { chrome } = useState(['chrome'])
    const { i18n } = useState('commune', ['i18n'])
    const breadcrumb = [
      {
        label: i18n.value.communeAddresses.title?.replace(/<[^>]*>/g, ''),
        url: '',
      },
    ]
    const { forms } = useState('faq', ['forms'])
    const { user } = useState(['user'])
    const { RESET_FORMS } = useMutations('faq', ['RESET_FORMS'])
    const idFormulaire: string = i18n.value.communeAddresses.idForm

    const disableBtnSubmit = ref(false)
    const feedbackWsError = ref('')
    const feedbackMessage = ref('')
    const hasError = ref(false)
    const isLoading = ref(false)
    const isSended = ref(false)
    const formulaireAdresse = ref({})

    const adresse = ref('')
    const compteur = ref('')
    const radio = ref('')
    const remarque = ref('')
    const ean = ref('')

    onMounted(() => {
      $logger.debug('Address:mounted')
    })

    const onSubmit = () => {
      isLoading.value = true
      for (const form of forms.value) {
        if (form.type === 'text') {
          adresse.value = form.value
        }
        if (form.type === 'compteur') {
          compteur.value = form.value
        }
        if (form.type === 'radio') {
          radio.value = form.value
        }
        if (form.type === 'textarea') {
          remarque.value = form.value
        }
        if (form.type === 'EAN') {
          ean.value = form.value
        }
      }

      Axios.post(`${getApiUrl()}/commune/contact`, {
        Section: 'MyResa Communes',
        SousSection:
          radio.value === 'DELETE'
            ? 'Supprimer un point de consommation'
            : 'Ajouter un point de consommation',
        Ean: ean.value,
        Commune: user.value.commune.localite,
        Compteur: compteur.value,
        Remarque: remarque.value,
        Adresse: adresse.value,
        Email: user.value.email,
        Destinataire: 'someone@resa.be',
      })
        .then(resp => {
          RESET_FORMS()
          isSended.value = true
          isLoading.value = false
          window.scroll({
            top: 0,
            behavior: 'smooth',
          })
        })
        .catch(e => {
          isLoading.value = false
          hasError.value = true
          feedbackMessage.value = e.response.data.message
        })
    }
    const globalI18n = computed(() => chrome.value.data.i18n)

    const nav = () => {
      ctx.root.$router.push({ name: 'CommuneDashboard' })
    }

    return {
      message,
      i18n,
      breadcrumb,
      ...useGetters('user', ['isAdmin']),
      onSubmit,
      isLoading,
      disableBtnSubmit,
      feedbackWsError,
      hasError,
      feedbackMessage,
      globalI18n,
      formulaireAdresse,
      isSended,
      nav,
      idFormulaire,
    }
  },
})
