




























































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'label-gravity',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const input = ref('')

    return {
      input,
      form: props.content,
    }
  },
})
